import * as React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Image } from "../../components/Image";
import { Link } from "gatsby";
import { GalleryImageSizedUrl } from "../../components/ImageGallery";
import { css } from "@emotion/react";
import { GlobalStyles } from "../../components/GlobalStyles";
import SEO from "../../components/seo";
import { useReturnToUrl } from "../../hooks/useReturnToUrl";
import { useLocation } from "@reach/router";

const maxWidth = 1000;

const GalleryPhotoPage = () => {
  return (
    <React.Fragment>
      <SEO title="Wedding photo" keywords={[`wedding`, `jack`, `nicola`]} />
      <GlobalStyles />
      <Router
        css={css({
          display: "flex",
          flexDirection: "column",
          placeContent: "center",
          placeItems: "center",
          minHeight: "calc(100vh - 16px)",
          padding: 8
        })}
        basepath="/gallery-img"
      >
        <GalleryPhotoRoute path="/:imageKey" />
      </Router>
    </React.Fragment>
  );
};

interface GalleryPhotoRouteProps extends RouteComponentProps {
  imageKey?: string;
}

export const GalleryPhotoRoute = React.memo((props: GalleryPhotoRouteProps) => {
  // Have to do this state thing so that `atob` happens inside a useEffect so
  // that it's browser only
  const [url, setUrl] = React.useState<string>(undefined);
  const location = useLocation();

  React.useEffect(() => {
    const withoutSize = atob(props.imageKey);
    setUrl(
      GalleryImageSizedUrl(
        withoutSize,
        { w: maxWidth * 2 },
        { location }
      ).toString()
    );
  }, [setUrl, props.imageKey]);

  return (
    <>
      {url && <BackButton />}
      <Image
        innerCss={css({
          borderRadius: 16,
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          overflow: "hidden",
          lineHeight: 0,
          width: "inherit",
          height: "inherit"
        })}
        loadingCss={css({
          width: "100%",
          height: "calc(100vh - 16px)"
        })}
        imageCss={css({
          maxWidth: "100%",
          maxHeight: "calc(100vh - 16px)",
          objectFit: "contain",
          width: "auto"
        })}
        src={url}
      />
    </>
  );
});

const BackButton = React.memo(() => {
  const { currentReturnToUrl } = useReturnToUrl();

  return (
    <Link
      to={currentReturnToUrl}
      css={css({
        textDecoration: "inherit",
        color: "inherit",
        position: "absolute",
        top: 24,
        left: 24,
        background: "white",
        padding: 12,
        borderRadius: 16,
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
      })}
    >
      Back
    </Link>
  );
});

export default GalleryPhotoPage;
